// default

<template>
    <v-dialog v-model="dialog" max-width="500px" persistent><v-card>
        <v-card-title>
          <span class="headline">Use Google's location service?</span>
        </v-card-title>
  	<v-container fluid pa-1>
		<v-row v-for="(item, index) in items" :key="index" :draggable="dragEnabled" @dragover.prevent
		@dragstart="dragstart(item, index, $event)" @dragend="dragend(item, index, $event)" @drop="drop(item, index, $event)"
		@dragenter.prevent="dragenter(item, index, $event)" @dragleave.prevent="dragleave(item, index, $event)"
			justify="center" align="center" dense
		>
			<v-col cols="12" sm="2">
				<p class="mb-0 text-right">{{ item.name }}</p>
				<!--<v-text-field dense flat hide-details readonly reverse :value="item.name">
				</v-text-field>-->
			</v-col>
			<v-col cols="12" sm="8">
				<v-text-field dense solo hide-details :value="item.name">
				<template slot="prepend">
					<v-icon @mousedown="dragEnable" style="cursor: move;">swap_vert</v-icon>
				</template>
				</v-text-field>
			</v-col>
			<v-col cols="12" sm="2">
				<v-switch v-model="item.name" dense solo hide-details filled color="success" class="mt-0"></v-switch>				
			</v-col>
		  </v-row>
	</v-container>
	<v-card-actions><v-btn text color="orange" @click="onCancel">Abbrechen</v-btn></v-card-actions>
	</v-card>
    </v-dialog>          
</template>
<script>
export default {
	name: 'default',
	components: {
	},
	data () {
		return {
			dialog: false,
			items: [
				{ name: 'A', pos: 1, },
				{ name: 'B', pos: 2, },
				{ name: 'C', pos: 3, },
			],
			dragItem: {},
			dragEnabled: false,
		}
	},
	computed: {
	},
	mounted() {
		this.dialog = true
	},
	methods: {
		sortRows() {
			this.items.sort((a, b) => {
          		return a.pos - b.pos
        	})
		},
		dragEnable() {
			this.dragEnabled = true
		},
		dragstart(item, idx) {
			if(this.dragEnabled) {
				this.dragItem = item
				console.log('dragstart', item.name, 'idx:', idx)
			}
		},
		dragend(item, idx) {
			if(this.dragEnabled) {
				this.dragEnabled = false
				this.dragItem = {}
				console.log('dragend', item.name, 'idx:', idx)
			}
		},
		dragenter(item, idx) {
			if(this.dragEnabled && item.name != this.dragItem.name) {
				console.log('dragenter', item.name, 'idx:', idx)
				const drapItemPos = this.dragItem.pos
				this.dragItem.pos = item.pos
				item.pos = drapItemPos
				this.sortRows()
			}
		},
		dragleave(item, idx) {
		},
		drop(item, idx) {
			//console.log('drop', this.dragItem.name, 'on', item.name, 'idx:', idx)
		},
		onCancel() {
			this.dialog = false
		}
	},
	watch: {
		items: {
			handler(after, before) {
				console.log(after)
			},
			deep: true
		},
	},
};
</script>
<style>
</style>